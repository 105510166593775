import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/aav/www/braucher.org/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Image from '../components/image';
import SEO from '../components/seo';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Home" keywords={['gatsby', 'application', 'react']} mdxType="SEO" />
    <h2>{`Your Local Texanmeister`}</h2>
    <p>I practice traditional Christian braucherei (powwow) in the Austin metro area of Texas, as transmitted within the <a href='https://www.pagermanpowwow.com/the-phoenix-line'>Phoenix Line</a> of powwowing, of Cumberland County, Pennsylvania.</p>
    <p>Please <a href='mailto:arthur@braucher.org?subject=Query from a Braucher.org visitor.'>contact me</a> if you have any questions or would like to arrange a consultation.</p>
    <h2>{`Powwow?`}</h2>
    <ul>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.pagermanpowwow.com/the-phoenix-line"
          }}>{`The Phoenix Line`}</a></strong>{`: The Phoenix Line of powwowing, Cumberland County, Pennsylvania.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.pagermanpowwow.com/"
          }}>{`The Powwow Guy`}</a>{` — Robert Phoenix`}</strong>{`: Robert started the Phoenix Line to preserve and transmit traditional Pennsylvania German powwow.`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://www.powwowdoctor.com/"
          }}>{`Powwow Doctor`}</a>{` — Jason David`}</strong>{`: "A home of Pennsylvania Dutch Powwow and its various practices."`}</li>
      <li parentName="ul"><strong parentName="li"><a parentName="strong" {...{
            "href": "https://genuinebraucherei.com/"
          }}>{`Braucherei As It Should Be`}</a>{` — Michael Meehan`}</strong>{`: "Genuine Braucherei (powwow) from a Christian Braucher."`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      